<template>
  <div class="news-tags">
    <p class="news-header__rubric" v-for="(tag, key) in getTagsList"
       :key="`tag${key}`"
       v-show="tag"
       @click="setTag(tag)">
      {{ tag.title }}
    </p>
  </div>
</template>

<script>
import currentYear from '@/mixins/currentYear'

export default {
  name: 'TagsList',

  props: {
    tags: {
      type: Array,
      required: false,
      default: () => []
    },
    systemTagList: {
      type: String,
      required: false,
      default: ''
    },
    convocationId: {
      type: Number,
      default: 2
    }
  },

  data () {
    return {
      structure: []
    }
  },

  async mounted () {
    this.$store.dispatch('apiMemberList', {
      year: this.getCurrentYear()
    })
    this.$store.dispatch('setProject')

    let res = await fetch(`${this.$store.getters.getUrlApi}api/structure?convocation=${this.convocationId}`, {
      headers: {
        Localization: this.$store.getters.GET_LANG ? 'ru' : 'eng'
      }
    })
    res = await res.json()
    this.structure = await res.data
  },

  watch: {
    '$store.getters.GET_LANG' () {
      this.$store.dispatch('apiMemberList', {
        year: this.getCurrentYear()
      })
    }
  },

  methods: {
    setTag (tag) {
      if (tag.isMember) {
        this.$router.push('/member_profile/' + tag.isMember.id)
      } else if (tag.isStructure) {
        this.$router.push('/structure_list/' + tag.isStructure.id)
      } else if (tag.isProject) {
        this.$router.push(`/detail_project/${tag.isProject.id}`)
      } else {
        if (this.systemTagList) {
          this.$store.commit('setFilterSystemTagList', this.systemTagList)
        }
        this.$store.commit('setFilterTag', tag.title)
        this.$router.push('/news?tab=News')
      }
    }
  },

  computed: {
    getMemberList () {
      return this.$store.getters.getMemberList
    },
    getProjectList () {
      return this.$store.getters.getProject
    },

    getStructure () {
      const sortedStructure = []

      this.structure.forEach((structure) => {
        structure.units.forEach((detail) => {
          sortedStructure.push({
            id: detail.id,
            title: detail.name
          })
        })
      })

      return sortedStructure
    },

    getTagsList () {
      return this.tags.map((tag) => {
        let splittedTag = tag.split(' ')
        splittedTag = splittedTag.length > 1 ? splittedTag[1][0] : null

        let membersList = []

        if (splittedTag && this.getMemberList && this.getMemberList[splittedTag]) {
          membersList = this.getMemberList[splittedTag].filter((member) => {
            let firstLastName = ''
            let firstLastNameEng = ''

            if (member.fullName) {
              firstLastName = member.fullName.split(' ')
              firstLastName = firstLastName[1] + ' ' + firstLastName[0]
            }

            if (member.fullNameEng) {
              firstLastNameEng = member.fullNameEng.split(' ').reverse().join(' ')
            }

            return firstLastName.toLowerCase() === tag.toLowerCase() || firstLastNameEng.toLowerCase() === tag.toLowerCase()
          })
        }

        const structureFilterdList = this.getStructure.filter((structure) => structure.title === tag)
        const project = this.getProjectList.find(el => el.name?.toLowerCase() === tag.toLowerCase()) ?? null
        return {
          title: tag,
          isProject: project,
          isMember: membersList.length ? membersList[0] : null,
          isStructure: structureFilterdList.length ? structureFilterdList[0] : null
        }
      })
    }
  },

  mixins: [currentYear]
}

</script>

<style scoped lang="scss">

  .news-tags {
    display: flex;
    flex-wrap: wrap;

    margin-bottom: 2.5rem;

    p {
      margin: 0.35rem 0.625rem 0.35rem 0;
    }
  }

  .news-header {
    display: flex;
    margin-bottom: 1.18rem;
    align-items: center;

    &__rubric {
      background: #EEF4FA;
      border-radius: 3px;
      font-weight: 600;
      font-size: 0.8125rem;
      line-height: 123%;
      padding: .375rem .625rem;
      /*text-transform: uppercase;*/
      color: #003E78;
      opacity: 0.8;
      cursor: pointer;
    }
    &__date {
      font-size: 0.875rem;
      margin-left: 0.625rem;
    }
  }

</style>
